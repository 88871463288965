import React from "react"
import GeneralFormConfirmation from "../components/Form/GeneralFormConfirmation"
import Layout from "../helper/layout"

const GeneralFormConfirmationPage = () => {
  return (
    <Layout>
      <GeneralFormConfirmation />
    </Layout>
  )
}

export default GeneralFormConfirmationPage
